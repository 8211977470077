<template>
  <div class="max-w-md mx-auto bg-gray-200 dark:bg-gray-900 rounded-xl shadow-md overflow-hidden md:max-w-5xl">
    <h1 class="text-8xl font-bold pb-5">{{ msg }}</h1>
    <div class="flex justify-center pb-2">
      <img src="../assets/orb_hunter.png" width="320" height="240" alt="Sepuworld the hunter of orbs"/>
    </div>
    <p class="pb-20">
      Welcome, I am your orb hunter<br>
      I am pondering the world, and I am pondering you.<br>
    </p>
    <div class="flex justify-center">
      <img src="../assets/orbs.png"/>
    </div>
    <div class="flex justify-center pt-20 pb-20">
      <video width="320" controls autoplay="true" loop>
        <source src="../assets/pyrite_time.mp4" type="video/mp4">
      Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pondering',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
